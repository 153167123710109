const img = document.getElementById("Shell");

const init = () => {
  img.classList.add("Shell--active");
};

if (img.complete && img.naturalHeight !== 0) {
  init();
} else {
  img.addEventListener("load", init);
}
